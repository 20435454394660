.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-small {
  height: 50px;
}

.loader-medium {
  height: 200px;
}

.loader-large {
  height: 400px;
}

.loader-full {
  height: 100vh;
}
