/* Table style */
table {
  width: 100% !important;
  border-collapse: collapse;
}

/* Table header style */
table thead {
  background-color: #f2f2f2;
}

/* Table header cell style */
table th {
  padding: 10px;
  text-align: left;
  font-weight: bold;
}

/* Table body style */
table tbody {
  background-color: #fff;
}

/* Table row style */
table tr {
  border-bottom: 1px solid #ddd;
}

/* Table cell style */
table td {
  padding: 10px;
}

/* Alternating row colors */
table tr:nth-child(even) {
  background-color: #f9f9f9;
}

table tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

/* Custom style for text content article */
.corps {
  white-space: break-spaces !important;
}
