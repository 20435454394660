@media screen and (max-width: 768px) {
  .hide-mobile-landscape {
    display: none;
  }

  .w-dropdown-list.w--open {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .w-dropdown-list {
    position: relative;
  }

  .dropdown-toggle[aria-expanded="true"] .dropdown-button {
    transform: rotate(-180deg);
  }
}

.skeleton-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: inherit; /* Inherit the border-radius of the parent element */
  background: linear-gradient(to right, #f6f7f8 25%, #e0e0e0 50%, #f6f7f8 75%);
  background-size: 200% 100%; /* Increase the size of the background image */
  z-index: 1; /* Make sure the skeleton loader is on top of the content */
  animation: skeleton 1s infinite linear;
}

@keyframes skeleton {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

[ms-code-skeleton] {
  background-clip: padding-box;
}
